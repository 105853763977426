<template>
  <div class="home" v-if="isRender">
    <!-- 移动端头部 -->
    <header class="header" ref="header">
      <div class="container-phone">
        <div class="header-left">
          <div class="logo">
            <a @click="clicknav('banner')">
              <h1 :style="{ background: 'url(' + backgroundUrl + ') 0 0 / contain no-repeat' }">
                i餐饮、iPad POS System、餐饮管理、免费餐饮管理、免费点餐系统、POS、点餐系统、点餐、出菜、营收报表、强大功能
              </h1>
            </a>
          </div>
        </div>
        <div class="header-right" @click="showMenu = true">
          <div class="line"></div>
          <p>{{ $t('m.20010') }}</p>
        </div>
      </div>
    </header>
    <div class="banner" ref="banner">
      <div class="content">
        <div class="item" v-for="(item, index) in bannerMobile" :key="index">
          <p class="item-title">{{ item.text }}</p>
          <p class="item-desc">{{ item.content }}</p>
          <div class="item-btn" v-if="index == 0" @click="$router.push({ name: 'Download' })">
            <p>{{ $t('m.20002') }}</p>
            <span>{{ appData.qrCodeText.content }}</span>
          </div>
          <div class="item-btn item-btn-hd" v-if="index == 0" @click="$router.push({ name: 'DownloadHd' })">
            <p>{{ $t('m.20015') }}</p>
            <span>{{ appData.qrCodeHdText.content }}</span>
          </div>
          <div class="item-img">
            <img :src="item.image" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 公司介绍、企业文化 -->
    <section ref="aboutUs">
      <section class="introduction" :style="{ background: 'url(' + introductionBgMobile + ') center center / cover no-repeat' }">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l white"></span>
            <h5 class="h5-w">{{ introduction.text }}</h5>
            <span class="pro-t-r white"></span>
          </div>
          <p v-for="(item, index) in introduction.list" :key="index">{{ item.text }}</p>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 企业文化 -->
      <section class="culture">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ appComtent.cultureList && appComtent.cultureList.content.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <ul>
            <li v-for="(item, index) in cultureList" :key="index">
              <img :src="item.image" alt="" />
              <p>{{ item.text }}</p>
              <span>{{ item.content }}</span>
            </li>
          </ul>
        </div>
      </section>
    </section>

    <section ref="icatering">
      <!-- 餐饮企业老板痛点 -->
      <section class="pain-point">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ appComtent.painPointList && appComtent.painPointList.content.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <ul>
            <li v-for="(item, index) in painPointList" :key="index">
              <p class="title">{{ item.text }}</p>
              <p class="desc">{{ item.content }}</p>
            </li>
          </ul>
        </div>
      </section>

      <!-- 餐饮全业态/全场景覆盖 -->
      <section class="business-scene" :style="{ background: 'url(' + businessSceneBgMobile + ') center center / cover no-repeat' }">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l white"></span>
            <h5 class="h5-w">{{ appComtent.scenesList && appComtent.scenesList.content.text }}</h5>
            <span class="pro-t-r white"></span>
          </div>
          <div class="content-title">{{ appComtent.scenesList && appComtent.scenesList.content.content }}</div>
          <ul>
            <li v-for="(item, index) in scenesList" :key="index">
              <img :src="item.image" alt="" />
              <span>{{ item.text }}</span>
            </li>
          </ul>
          <div class="sub-content">
            <div class="sub-content-title">{{ appComtent.deviceList && appComtent.deviceList.content.text }}</div>
            <div class="sub-content-desc">
              {{ appComtent.deviceList && appComtent.deviceList.content.content }}
            </div>
            <ul>
              <li v-for="(item, index) in deviceList" :key="index">
                <img :src="item.image" alt="" />
                <p>{{ item.text }}</p>
              </li>
              <div class="mask"></div>
            </ul>
          </div>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 终生免费使用 -->
      <section class="zero-threshold">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ appComtent.zeroThreshold && appComtent.zeroThreshold.content.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="content-img">
            <img :src="appComtent.zeroThreshold && appComtent.zeroThreshold.content.image" alt="" />
          </div>
          <div class="content-text">
            <div v-for="(item, index) in zeroThresholdList" :key="index">
              <p class="title">{{ item.text }}</p>
              <p class="desc" v-html="item.content"></p>
            </div>
          </div>
        </div>
      </section>
      <!-- 搭建门店 -->
      <section class="create-store" :style="{ background: 'url(' + createStoreBgMobile + ') center center / cover no-repeat' }">
        <div class="content">
          <p>{{ createStore.text }}</p>
          <p>{{ createStore.content }}</p>
          <span></span>
          <p class="desc">
            <span v-for="(item, index) in createStore.list" :key="index">
              {{ item.text }}
            </span>
          </p>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 外卖聚合配送 无惧运力紧张 -->
      <section class="takeout">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ takeout.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ takeout.content }}</div>
          <div class="img">
            <img :src="takeout.image" alt="" />
          </div>
        </div>
        <div class="bg-img" v-if="appComtent.takeoutBgMobile">
          <img :src="appComtent.takeoutBgMobile.content" alt="" />
        </div>
      </section>
      <!-- 「i餐饮」第三方外卖管理 -->
      <section class="takeout-platform" :style="{ background: 'url(' + takeoutPlatformBgMobile + ') center center / cover no-repeat' }">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l white"></span>
            <h5 class="h5-w">{{ appComtent.takeoutPlatform && appComtent.takeoutPlatform.content.text }}</h5>
            <span class="pro-t-r white"></span>
          </div>
          <div class="desc">{{ appComtent.takeoutPlatform && appComtent.takeoutPlatform.content.content }}</div>
          <div class="text">{{ appComtent.takeoutPlatformText && appComtent.takeoutPlatformText.content }}</div>
          <ul>
            <li v-for="(item, index) in takeoutPlatformList" :key="index"><img :src="item" alt="" /></li>
          </ul>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 智能订单打印 -->
      <section class="order-print">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ orderPrint.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ orderPrint.content }}</div>
          <div class="content-text">
            <div v-for="(item, index) in orderPrintList" :key="index">
              <p class="title">{{ item.text }}</p>
              <p class="text">{{ item.content }}</p>
            </div>
          </div>
          <div class="content-img">
            <img :src="orderPrint.image" alt="" />
          </div>
        </div>
      </section>
      <!-- 多门店 轻管理 经营数据，菜单共享！ -->
      <section class="operating-data">
        <div class="content">
          <div class="content-img" v-if="operatingDataImg[0]">
            <img :src="operatingDataImg[0]" alt="" />
          </div>
          <div class="content-m">
            <p class="desc">{{ operatingData.text }}</p>
            <p class="desc">{{ operatingData.content }}</p>
            <span></span>
            <p class="text">{{ appComtent.operatingDataText && appComtent.operatingDataText.content }}</p>
            <img :src="operatingData.image" alt="" />
          </div>
          <div class="content-img" v-if="operatingDataImg[1]">
            <img :src="operatingDataImg[1]" alt="" />
          </div>
        </div>
      </section>
      <!-- 全能聚合支付 -->
      <section class="aggregate-payment">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ aggregatePayment.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ aggregatePayment.content }}</div>
          <div class="content-text">
            <p v-for="(item, index) in aggregatePaymentList" :key="index">{{ item.text }}</p>
          </div>
          <div class="content-img">
            <img :src="aggregatePayment.image" alt="" />
          </div>
        </div>
      </section>
      <!-- 多场景开票服务 -->
      <section class="billing-service">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ billingService.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ billingService.content }}</div>
          <div class="content-text">
            <p class="title">{{ billingServiceContent.text }}</p>
            <p class="text">{{ billingServiceContent.content }}</p>
          </div>
          <div class="content-img">
            <img :src="billingService.image" alt="" />
          </div>
        </div>
      </section>
    </section>
    <!-- “i充电”共享充电服务 -->
    <section class="charge-service" ref="icharge">
      <div class="content">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ chargeService.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="desc">{{ chargeService.content }}</div>
        <div class="content-text">
          <div v-for="(item, index) in chargeServiceList" :key="index">
            <p class="title">{{ item.text }}</p>
            <p class="text">{{ item.content }}</p>
          </div>
        </div>
        <div class="content-img">
          <img :src="chargeService.image" alt="" />
        </div>
      </div>
    </section>
    <!-- 配套硬件 全国联保 -->
    <section class="hardware-list" ref="product">
      <div class="content">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ hardwareList.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="desc">{{ hardwareList.content }}</div>
        <ul>
          <li v-for="(item, index) in hardwareList.list" :key="index">
            <img :src="item.image" alt="" />
            <p>{{ item.text }}</p>
            <span v-if="item.content">{{ item.content }}</span>
          </li>
        </ul>
      </div>
    </section>
    <!-- 专属客服 真挚服务start -->
    <section class="about" ref="contactUs">
      <div class="content">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ about.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="desc">{{ about.content }}</div>
        <ul>
          <li v-for="(item, index) in about.list" :key="index">
            <img :src="item.image" alt="" />
            <p>{{ item.text }}</p>
            <span>{{ item.content }}</span>
          </li>
        </ul>
        <div class="contact">
          <div class="title">{{ service.text }}</div>
          <div class="text">{{ service.content }}</div>
          <el-form ref="form" :model="form" :rules="rules" class="form">
            <el-form-item :label="$t('m.20003')" prop="name">
              <el-input @input="(e) => handleInput(e, 'name')" :maxlength="30" v-model="form.name" :placeholder="$t('m.20004')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.20005')" prop="mobile">
              <el-input @input="(e) => handleInput(e, 'mobile')" :maxlength="13" v-model="form.mobile" :placeholder="$t('m.20006')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.20007')" prop="notes">
              <el-input @input="(e) => handleInput(e, 'notes')" :maxlength="200" type="textarea" rows="4" v-model="form.notes" :placeholder="$t('m.20008')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="onSubmit('form')">{{ $t('m.20009') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </section>
    <div class="hr"></div>
    <footer class="footer">
      <div class="content">
        <div class="logo">
          <img v-if="backgroundUrl" :src="backgroundUrl" alt="" />
          <span>{{ appData.site_name && appData.site_name.content }}</span>
        </div>
        <div class="link">
          <ul>
            <!-- 首页 -->
            <li @click="clicknav('banner')">{{ $t('m.20011') }}</li>
            <!-- 关于我们 -->
            <li @click="clicknav('aboutUs')">{{ $t('m.20014') }}</li>
            <!-- i餐饮 -->
            <li @click="clicknav('icatering')">{{ $t('m.20012') }}</li>
            <!-- i充电 -->
            <li @click="clicknav('icharge')">{{ $t('m.20013') }}</li>
            <!-- 硬件中心 -->
            <li @click="clicknav('product')">{{ $t('m.20021') }}</li>
            <!-- 登录i餐饮 -->
            <li v-if="appData.backendUrl">
              <a :href="appData.backendUrl.content" target="_blank">{{ $t('m.20001') }}</a>
            </li>
          </ul>
          <ul>
            <!-- 联系我们 -->
            <li @click="clicknav('contactUs')">{{ $t('m.20022') }}</li>
            <!-- 服务热线 -->
            <li v-if="appData.service_hotlin">
              {{ appData.service_hotlin.title }}：
              <a :href="`tel:${appData.service_hotlin.content}`">{{ appData.service_hotlin.content }}</a>
            </li>
            <!-- 邮箱 -->
            <li v-if="appData.email">
              {{ appData.email.title }}：
              <a :href="`mailto:${appData.email.content}`">{{ appData.email.content }}</a>
            </li>
          </ul>
        </div>
        <div class="qrcode">
          <div v-if="appData.qrCode">
            <img :src="appData.qrCode.content" :alt="appData.qrCodeText.title" />
            <p>{{ appData.qrCodeText.title }}</p>
            <span>{{ appData.qrCodeText.content }}</span>
          </div>
          <div v-if="appData.qrCodeHd">
            <img :src="appData.qrCodeHd.content" :alt="appData.qrCodeHdText.title" />
            <p>{{ appData.qrCodeHdText.title }}</p>
            <span>{{ appData.qrCodeHdText.content }}</span>
          </div>
          <div v-if="appData.qrCodeApplet">
            <img :src="appData.qrCodeApplet.content" :alt="appData.qrCodeAppletText.title" />
            <p>{{ appData.qrCodeAppletText.title }}</p>
            <span>{{ appData.qrCodeAppletText.content }}</span>
          </div>
        </div>
        <div class="hr"></div>
        <div class="copyright">
          <p>{{ appData.copyright_repast && appData.copyright_repast.content }}</p>
          <p>
            <a target="_blank" v-if="appData.recordLink" :href="appData.recordLink.content">{{ appData.recordInfo && appData.recordInfo.content }}</a>
            <a target="_blank" v-if="appData.telecomLink" :href="appData.telecomLink.content">{{ appData.telecom && appData.telecom.content }}</a>
          </p>
        </div>
      </div>
    </footer>
    <!-- 底部end -->
    <!-- 菜单栏 -->
    <el-dialog :lockScroll="false" @open="openMenu" @close="closeMenu" class="menu" :visible.sync="showMenu" width="90%" top="20vh">
      <div class="menu-list">
        <div class="menu-item" :class="{ active: item.index === navActiveIndex }" v-for="(item, index) in navList" :key="item.index" @click="clicknav(item.ref)">
          {{ item.name[$i18n.locale] }}
        </div>
        <div class="menu-item" v-if="appData.backendUrl">
          <a :href="appData.backendUrl.content" target="_blank">{{ $t('m.20001') }}</a>
        </div>
      </div>
    </el-dialog>
    <!-- 表单提交结果 -->
    <el-dialog
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :lockScroll="false"
      @open="openMenu"
      @close="closeMenu"
      center
      :title="$t('m.20016')"
      class="result"
      :visible.sync="showResult"
      width="80%"
    >
      <el-result :icon="icon" :title="title" :subTitle="subTitle"> </el-result>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleOk">{{ $t('m.20019') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from '@/mixin/indexMixin'
export default {
  name: 'Home',
  mixins: [indexMixin],
  computed: {
    // bannerMobile
    bannerMobile() {
      return (this.appComtent.bannerMobile && this.appComtent.bannerMobile.content.list) || []
    },
    introductionBgMobile() {
      return (this.appComtent.introductionBgMobile && this.appComtent.introductionBgMobile.content) || ''
    },
    businessSceneBgMobile() {
      return (this.appComtent.businessSceneBgMobile && this.appComtent.businessSceneBgMobile.content) || ''
    },
    createStoreBgMobile() {
      return (this.appComtent.createStoreBgMobile && this.appComtent.createStoreBgMobile.content) || ''
    },
    takeoutPlatformBgMobile() {
      return (this.appComtent.takeoutPlatformBgMobile && this.appComtent.takeoutPlatformBgMobile.content) || ''
    },
  },

  data() {
    return {
      form: {
        name: '',
        mobile: '',
        notes: '',
        platform: 'h5',
        email: 'xxx@xxx.xxx',
        source: 9,
      },
      showMenu: false,
      loading: false,
    }
  },

  methods: {
    clicknav(ref) {
      let headerHeight = this.$refs['header'].offsetHeight || 0
      window.scrollTo({
        top: this.$refs[ref].offsetTop - headerHeight + 1,
        behavior: 'instant',
      })
      this.showMenu = false
    },
    openMenu() {
      document.body.style.position = 'relative'
      document.body.style.width = '100%'
      document.body.style.overflow = 'hidden'
    },
    closeMenu() {
      document.body.style = {}
    },
  },
}
</script>
<style lang="scss" scoped>
@mixin title($size, $color) {
  font-size: vv($size);
  @include fontMedium;
  font-weight: 500;
  color: $color;
}
@mixin desc($size, $color, $mgTop: 4) {
  font-size: vv($size);
  @include fontRegular;
  color: $color;
  margin-top: vv($mgTop);
}

.home {
  overflow-x: hidden;
  padding-top: vv(48);
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: vv(48);
    background-color: #000;
    z-index: 9;
    .container-phone {
      @include flex($justify: space-between, $align: center);
      height: vv(48);
      background-color: #000;
      padding: vv(8) vv(20);
      box-sizing: border-box;
    }
    .header-left {
      @include flex($justify: space-between, $align: center);
      .logo {
        h1 {
          overflow: hidden;
          text-indent: -9999px;
          width: vv(32);
          height: vv(32);
        }
      }
    }
    .header-right {
      @include flex($align: center);
      .line {
        width: vv(20);
        height: vv(3);
        background-color: #fff;
        position: relative;
        border: 0;
        &::before,
        &::after {
          content: '';
          display: inline-block;
          width: vv(20);
          height: vv(3);
          background-color: #fff;
          position: absolute;
          left: 0;
          border: 0;
        }
        &::before {
          bottom: vv(6);
        }
        &::after {
          top: vv(6);
        }
      }
      p {
        font-size: vv(15);
        @include fontRegular;
        margin-left: vv(8);
        color: #fff;
      }
    }
  }
  .banner {
    background-image: linear-gradient(360deg, #e9f5fa 0%, #edfafd 49%, #f8fdfe 100%);
    padding: vv(30) vv(20) 0;
    .item {
      width: 100%;
      p.item-title {
        font-size: vv(32);
        @include fontSemibold;
        font-weight: 600;
        color: $black;
      }
      p.item-desc {
        font-size: vv(20);
        @include fontRegular;
        color: $black;
        letter-spacing: vv(10);
      }
      .item-btn {
        padding: vv(5) 0;
        width: vv(164);
        border-radius: vv(4);
        @include flex($direction: column, $justify: center, $align: center);
        margin-top: vv(14);
        box-sizing: border-box;
        border: vv(1) solid $black;
        background-color: $black;
        p {
          @include title(16, #fff);
        }
        span {
          @include desc(12, #fff, 0);
          transform: scale(0.9);
        }
        &:first-of-type {
          p {
            letter-spacing: vv(3);
          }
        }
      }
      .item-btn-hd {
        background-color: transparent;
        p,
        span {
          color: $black;
        }
      }
      .item-img {
        margin-top: vv(24);
        text-align: center;
        img {
          height: vv(188);
        }
      }
    }
  }
  // 公共标题
  .pro-title {
    display: flex;
    justify-content: center;
    align-items: center;
    > h5 {
      @include title(18, #333);
      margin: 0 vv(10);
      &.h5-w {
        color: #fff;
      }
    }
    .pro-t-l,
    .pro-t-r {
      width: vv(2);
      height: vv(14);
      background-color: $red;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: inline-block;
        width: vv(2);
        background-color: $red;
      }
      &::before {
        left: vv(-12);
        height: vv(6);
        opacity: 0.4;
      }
      &::after {
        left: vv(-6);
        height: vv(10);
        opacity: 0.7;
      }
    }
    .pro-t-r {
      &::before {
        left: vv(12);
      }
      &::after {
        left: vv(6);
      }
    }
    .pro-t-l.white,
    .pro-t-r.white {
      background-color: #fff;
      &::before {
        background-color: #fff;
        z-index: 2;
      }
      &::after {
        z-index: 2;
        background-color: #fff;
      }
    }
  }

  .introduction {
    position: relative;
    .mask {
      @include mask(1, rgba(0, 0, 0, 0.5));
    }
    .content {
      position: relative;
      z-index: 2;
      padding: vv(24) vv(20) vv(20);
      p {
        font-size: vv(14);
        color: #f1f1f1;
        @include fontRegular;
        margin-top: vv(16);
        line-height: vv(22);
        &:first-of-type {
          font-weight: 500;
          @include fontMedium;
          color: #fff;
        }
      }
    }
  }
  .culture {
    .content {
      padding: vv(24) vv(20) vv(20);
      ul {
        @include flex($direction: column);
        li {
          @include flex($direction: column, $align: center);
          padding: vv(24) vv(35);
          background-color: #fbfbfb;
          margin-top: vv(12);
          img {
            width: vv(60);
            height: vv(60);
            flex-shrink: 0;
          }
          p {
            @include title(20, $black);
          }
          span {
            @include desc(16, #4c4c4c);
            text-align: center;
          }
          &:first-child {
            margin-top: vv(16);
          }
        }
      }
    }
  }
  .pain-point {
    .content {
      padding: vv(24) vv(20) vv(20);
      ul {
        margin-top: vv(16);
        li {
          padding: vv(12);
          border-radius: vv(4);
          border: vv(1) solid rgba(255, 17, 17, 0.2);
          margin-top: vv(10);
          p.title {
            @include title(16, #333);
          }
          p.desc {
            @include desc(14, #4c4c4c, 8);
          }
        }
      }
    }
  }
  .business-scene {
    position: relative;
    @include bgFix;
    .content {
      position: relative;
      z-index: 2;
      padding: vv(24) vv(20) vv(20);
      .content-title {
        @include desc(14, #fff);
        text-align: center;
      }
      > ul {
        margin: vv(20) 0 vv(18);
        @include flex($direction: row, $wrap: wrap);
        li {
          width: 33.33%;
          text-align: center;
          @include flex($direction: column, $align: center);
          margin-bottom: vv(24);
          img {
            width: vv(48);
            height: vv(48);
            flex-shrink: 0;
          }
          span {
            @include desc(16, #fff, 12);
          }
        }
      }
      .sub-content {
        .sub-content-title {
          @include title(18, #fff);
          text-align: center;
          position: relative;
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: vv(24);
            height: vv(1);
            background-color: #fff;
          }
          &::before {
            left: 20%;
          }
          &::after {
            right: 20%;
          }
        }
        .sub-content-desc {
          @include desc(14, #fff);
        }
        > ul {
          margin-top: vv(16);
          position: relative;
          padding: vv(20) vv(20) 0;
          @include flex($wrap: wrap);
          li {
            position: relative;
            z-index: 2;
            width: 50%;
            @include flex($direction: column, $align: center);
            margin-bottom: vv(20);
            img {
              width: vv(144);
              height: vv(144);
              flex-shrink: 0;
            }
            p {
              @include desc(16, #fff, 0);
            }
          }
          > .mask {
            @include mask(0.1, #fff);
          }
        }
      }
    }
    > .mask {
      @include mask(0.2, #000);
    }
  }

  .zero-threshold {
    .content {
      padding: vv(26) vv(20) vv(20);
      .content-img {
        margin: vv(11) 0;
        text-align: center;
        img {
          height: vv(360);
        }
      }
      .content-text {
        padding: vv(24) vv(12) vv(16);
        background-color: #fff;
        box-shadow: 0 vv(3) vv(6) 0 rgba(0, 0, 0, 0.05);
        div {
          p.title {
            @include title(16, #333);
          }
          p.desc {
            @include desc(14, #4c4c4c, 8);
            word-spacing: -4px;
          }
          &:not(:last-child) {
            margin-bottom: vv(16);
          }
        }
      }
    }
  }

  .create-store {
    position: relative;
    @include bgFix;
    .content {
      height: vv(200);
      padding: 0 vv(92) vv(10);
      position: relative;
      z-index: 2;
      @include flex($direction: column, $justify: flex-end, $align: center);
      p {
        text-align: center;
        @include desc(14, #fff, 0);
        &.desc {
          span {
            @include desc(12, #f5f5f5, 0);
          }
        }
      }
      > span {
        display: inline-block;
        width: vv(24);
        height: vv(2);
        background-color: $red;
        margin: vv(8) 0;
      }
    }
    .mask {
      @include mask(0.4, #000);
    }
  }

  // 外卖聚合配送
  .takeout {
    position: relative;
    height: vv(242);
    .content {
      height: 100%;
      padding-top: vv(24);
      position: relative;
      z-index: 2;
      .desc {
        @include desc(14, #333);
        text-align: center;
      }
      .img {
        position: absolute;
        bottom: vv(-7);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        img {
          width: vv(85);
          height: vv(41);
        }
      }
    }
    .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: vv(242);
      background-color: #f9f9f9;
      overflow: hidden;
      img {
        height: vv(242);
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .takeout-platform {
    @include bgFix;
    position: relative;
    .content {
      position: relative;
      z-index: 2;
      padding: vv(26) vv(34) 0;
      .desc {
        @include desc(14, #fff);
        text-align: center;
      }
      .text {
        @include desc(14, #fff, 20);
        text-align: center;
      }
      ul {
        margin-top: vv(24);
        @include flex($wrap: wrap);
        li {
          width: 33.33%;
          @include flex($justify: center, $align: center);
          margin-bottom: vv(20);
          img {
            width: vv(80);
            height: vv(80);
            flex-shrink: 0;
          }
        }
      }
    }
    .mask {
      @include mask(1, rgba(0, 0, 0, 0.2));
    }
  }

  // 订单打印
  .order-print {
    background-color: #f9f9f9;
    .content {
      padding: vv(24) vv(20) vv(20);
      .desc {
        @include desc(14, #333);
        text-align: center;
      }
      .content-text {
        margin: vv(16) 0;
        background-color: #fff;
        padding: vv(24) vv(42) vv(16) vv(12);
        box-shadow: 0 vv(3) vv(6) 0 rgba(0, 0, 0, 0.05);
        div {
          p.title {
            @include title(16, #333);
          }
          p.text {
            @include desc(14, #4c4c4c, 8);
          }
          &:not(:last-child) {
            margin-bottom: vv(16);
          }
        }
      }
      .content-img {
        padding: vv(16) 0;
        text-align: center;
        background-color: #fff;
        img {
          height: vv(210);
        }
      }
    }
  }

  .operating-data {
    .content {
      padding: vv(23) vv(13) 0;
      @include flex;
      .content-img {
        flex-shrink: 0;
        img {
          height: vv(136);
        }
      }
      .content-m {
        flex: 1;
        @include flex($direction: column, $align: center);
        margin-top: vv(1);
        p.desc {
          @include desc(16, #333, 0);
        }
        p.text {
          @include desc(10, #333, 0);
          text-align: center;
        }
        > span {
          display: inline-block;
          width: vv(24);
          height: vv(2);
          margin: vv(8) 0;
          background-color: $red;
        }
        img {
          margin-top: vv(16);
          height: vv(56);
          flex-shrink: 0;
        }
      }
    }
  }
  // 全能聚合支付
  .aggregate-payment {
    background-color: #f9f9f9;
    .content {
      padding: vv(32) vv(20) vv(20);
      .desc {
        @include desc(14, #333);
        text-align: center;
      }
      .content-text {
        padding: vv(24) vv(12) vv(20) vv(22);
        background-color: #fff;
        margin: vv(16) 0;

        box-shadow: 0 vv(3) vv(6) 0 rgba(0, 0, 0, 0.05);
        > p {
          position: relative;
          @include desc(16, #333, 0);
          &:not(:first-child) {
            margin-top: vv(16);
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: vv(5);
            left: vv(-10);
            width: vv(2);
            height: vv(14);
            background-color: $red;
          }
        }
      }
      .content-img {
        background-color: #fff;
        padding-top: vv(24);
        text-align: center;
        img {
          height: vv(217);
        }
      }
    }
  }
  // 多场景开票服务
  .billing-service {
    .content {
      padding: vv(24) vv(20) vv(20);
      .desc {
        @include desc(14, #333, 0);
        text-align: center;
      }
      .content-text {
        padding: vv(24) vv(12) vv(20);
        box-shadow: 0 vv(3) vv(6) 0 rgba(0, 0, 0, 0.05);
        margin: vv(16) 0;
        p.title {
          @include title(16, #333);
        }
        p.desc {
          @include desc(14, #4c4c4c, 8);
        }
      }
      .content-img {
        padding: vv(16) 0;
        text-align: center;
        img {
          height: vv(210);
        }
      }
    }
  }

  // “i充电”共享充电服务
  .charge-service {
    background-color: #f9f9f9;
    .content {
      padding: vv(24) vv(20) vv(20);
      .desc {
        @include desc(14, #333);
        text-align: center;
      }
      .content-text {
        margin: vv(16) 0;
        background-color: #fff;
        padding: vv(24) vv(12) vv(4);
        div {
          margin-bottom: vv(16);
          p.title {
            @include title(16, #333);
          }
          p.text {
            @include desc(14, #4c4c4c, 8);
          }
        }
      }
      .content-img {
        background-image: linear-gradient(180deg, #fcf8f8 0%, #f4f3f3 100%);
        padding: vv(23) 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: vv(193);
        }
      }
    }
  }

  .hardware-list {
    .content {
      padding: vv(24) vv(20) vv(20);
      .desc {
        @include desc(14, #333);
        text-align: center;
      }
      ul {
        margin-top: vv(16);
        @include flex($wrap: wrap);
        li {
          width: 100%;
          background-image: linear-gradient(180deg, #fcf8f8 0%, #f4f3f3 100%);
          background-color: #f4f3f3;
          box-shadow: 0 vv(1) vv(2) 0 rgba(0, 0, 0, 0.05);
          border-radius: vv(16);
          @include flex($direction: column, $align: center);
          &:not(:first-child) {
            margin-top: vv(12);
          }
          padding: vv(18) 0 vv(20);
          img {
            height: vv(184);
            flex-shrink: 0;
          }
          p {
            @include desc(16, #333, 12);
          }
          span {
            margin-bottom: vv(-20);
            @include desc(14, #333, 0);
          }
        }
      }
    }
  }

  // 专属客服 真挚服务
  .about {
    background-image: linear-gradient(180deg, #ffffff 0%, #fff7f7 100%);
    .content {
      padding: vv(24) vv(20) vv(20);
      > .desc {
        @include desc(14, #333);
        text-align: center;
      }
      ul {
        margin-top: vv(20);
        @include flex;
        li {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include flex($direction: column, $align: center);
          img {
            height: vv(48);
            flex-shrink: 0;
          }
          p {
            @include title(16, #333);
            margin-top: vv(8);
          }
          span {
            @include desc(14, #333, 0);
          }
        }
      }
      .contact {
        margin-top: vv(24);
        padding: vv(24) vv(19) vv(16);
        background-color: #fff;
        text-align: center;
        .title {
          @include title(16, #333);
        }
        .text {
          @include desc(14, #ccc);
        }
        .form {
          margin-top: vv(20);
          padding: 0 vv(5);
          ::v-deep .el-form-item__label {
            @include title(14, #333);
          }
          ::v-deep .el-input__inner {
            color: $black;
          }
          ::v-deep .el-input__inner:focus,
          ::v-deep .el-textarea__inner:focus {
            border-color: $red;
          }
          .el-button {
            margin-top: vv(20);
            width: 100%;
            background-color: $red;
            border-radius: vv(4);
            padding: vv(9) 0;
            @include title(16, #fff);
          }
        }
      }
    }
  }

  > .hr {
    height: vv(8);
    background-color: #fff;
  }

  // 底部
  .footer {
    background-color: #1e1f20;
    .content {
      padding: vv(32) vv(20) vv(16);
      .logo {
        @include flex($align: center);
        img {
          height: vv(40);
          flex-shrink: 0;
        }
        span {
          font-size: vv(16);
          @include fontSemibold;
          font-weight: 600;
          color: #fff;
          margin-left: vv(12);
        }
      }
      .link {
        margin-bottom: vv(24);
        ul {
          margin-top: vv(24);
          @include flex($direction: column);
          li {
            @include desc(14, #eaeaea, 12);
            &:first-child {
              font-size: vv(14);
              @include fontSemibold;
              font-weight: 600;
              color: #fff;
              margin-top: 0;
            }
            a {
              @include desc(14, #eaeaea, 0);
            }
          }
        }
      }
      .qrcode {
        @include flex;
        div {
          @include flex($direction: column, $align: center);
          img {
            height: vv(100);
          }
          p {
            @include title(14, #fff);
            margin-top: vv(8);
          }
          span {
            @include desc(11, #eaeaea, 0);
            padding: 0 vv(8);
            text-align: center;
            display: inline-block;
            max-width: vv(100);
          }
          &:not(:last-child) {
            margin-right: vv(20);
          }
        }
      }
      > .hr {
        height: vv(1);
        border: 0;
        background-color: #595959;
        margin: vv(14) 0;
      }
      .copyright {
        p {
          @include desc(12, #bfbfbf, 0);
          margin-bottom: vv(6);
        }
        a {
          @include desc(12, #bfbfbf, 0);
          &:not(:first-child) {
            margin-left: vv(6);
          }
        }
      }
    }
  }
  .menu {
    ::v-deep .el-dialog {
      border-radius: vv(12);
    }
    ::v-deep .el-dialog__headerbtn {
      font-size: vv(24);
    }
    ::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
      color: #999;
    }
    .menu-list {
      @include flex($direction: column);
      .menu-item {
        padding-bottom: vv(8);
        border-bottom: vv(1) solid #f1f1f1;
        @include desc(18, #333, 20);
        &.active {
          @include title(18, $red);
        }
        &:first-child {
          margin-top: 0;
        }
        > a {
          color: #333;
        }
      }
    }
    .menu-close {
      position: absolute;
      right: vv(20);
      top: vv(20);
      img {
        height: vv(24);
      }
    }
    &.click {
      opacity: 1;
    }
  }
  .result {
    ::v-deep .el-dialog {
      border-radius: vv(12);
    }
    ::v-deep .el-result {
      padding: 0;
    }
    .dialog-footer {
      ::v-deep .el-button {
        background-color: $red;
        color: #fff;
        padding: 12px 40px;
        border-color: $red;
      }
    }
  }
}
</style>
